@import "./variables";

body {
  color: $primary;
}
.main-wrapper {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  &.logoin_box {
    background: $grey2;
  }
  .page-wrapper {
    min-height: 100vh;
    width: calc(100% - 240px);
    margin-left: 240px;
    display: flex;
    flex-direction: column;
    -webkit-transition: margin 0.1s ease, width 0.1s ease;
    transition: margin 0.1s ease, width 0.1s ease;
    &.full-page {
      width: 100%;
      margin-left: 0;
    }
    .page-content {
      flex-grow: 1;
      padding: 25px;
      margin-top: 60px;
      padding: 30px 80px;
    }
  }
  .tx-80 {
    font-size: 80px;
  }
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:focus,
  &:hover {
    background-color: $primaryHover;
    border-color: $primaryHover;
  }
}

.auth-page .auth-left-wrapper {
  width: 100%;
  height: 100%;
  // background-image: url("https://www.nobleui.com/html/template/assets/images/carousel/img6.jpg");
  background-size: cover;
  background-image: linear-gradient(to bottom, #000104f2, #727cf5bf),
    url("https://www.nobleui.com/html/template/assets/images/carousel/img6.jpg");
}

.card {
  box-shadow: 0 0 10px 0 #b7c0ce33;
  -webkit-box-shadow: 0 0 10px 0 #b7c0ce33;
  border: 1px solid #f2f4f9;
}
.grid-margin {
  margin-bottom: 1.5rem;
}
.stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.stretch-card {
  & > .card {
    box-shadow: 0px 0px 0px transparent;
    border: 0px;
    & > .card-body {
      padding: 0px;
    }
  }
  &.grey_box_ {
    & > .card {
      background-color: $grey2;
      border: 1px solid $grey3;
      & > .card-body {
        padding: 1.25rem;
      }
    }
  }
}

.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f2f4f9;
  border-radius: 0.25rem;
}
.icon-sm {
  width: 14px;
  height: 14px;
}
.card .card-title {
  color: #000;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
}
.form-group label {
  font-size: 0.875rem;
  line-height: 1.4rem;
  vertical-align: top;
  margin-bottom: 0.5rem;
}
.btn-icon {
  &.btn-sm-my {
    padding: 2px 5px;
    display: inline-flex;
    min-height: 25px;
    align-items: center;
  }
  svg {
    width: 15px;
    &.BiBarcodeReader {
      width: 22px;
      // height: 28px;
      fill: #fff;
    }
  }
}
.btn {
  &.btn-sm-my {
    padding: 2px 6px;
    display: inline-flex;
    min-height: 25px;
    align-items: center;
    font-size: 12px;
    line-height: 11px;
  }
}
.btn-icon-text {
  min-height: 35px;
  align-self: center;
  align-items: center;
  display: inline-flex;
  svg {
    width: 18px;
    margin-right: 6px;
    &.bluk_upload {
      height: auto;
    }
  }
}

.badge.badge-primary {
  background-color: #f0f2ff !important;
  color: #7888fc;
}
.badge.badge-secondary {
  background-color: #f3f6f9 !important;
  color: #7e8299 !important;
}
.badge.badge-success {
  background-color: #d3fbf9 !important;
  color: #6bcac2;
}
.badge.badge-danger {
  background-color: #fad7dd !important;
  color: #ee6e83;
}
.badge.badge-warning {
  background-color: #fff5e0 !important;
  color: #ffaf0f;
}
.badge.badge-info {
  background-color: #efe8ff !important;
  color: #9c6efc;
}
.badge.badge-dark {
  background-color: #d1d3e0 !important;
  color: #5b5b5b;
}
.badge {
  padding: 5px 7px;
}
.dvdata {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  margin-bottom: 3px;
}
.cstm_select input.Select-input {
  width: 100%;
  border: 0;
}
.heading_box {
  background-color: $primary;
  border-radius: 4px;
  padding: 15px;
  min-height: 70px;
  .card-title {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    text-transform: initial;
    align-self: flex-end;
    margin-bottom: 0px;
  }
}
.heading_box_2 {
  border-bottom: 1px solid $primary;
  .card-title {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
.btn-dark {
  background-color: $grey4;
  border-color: $grey4;
}
.nav_tabs_custom {
  .nav-item {
    margin-right: 3px;
    .nav-link {
      background-color: $grey2;
      border-color: $grey3;
      color: $primary;

      &.active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }
}
.noti_d__ {
  position: relative;
}
@import "sidebar.scss";
@import "header.scss";
@import "footer.scss";
@import "breadcrumb.scss";
@import "modal.scss";
@import "responsive.scss";
@import "custom.scss";
@import "input.scss";
