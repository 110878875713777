.inventory_tbl_sub_com {
  .sub_compoent {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.form-group {
  .upload_file {
    display: inline-flex;
    margin-bottom: 0px;
    input[type="file"]:disabled + div {
      opacity: 0.5;
    }

    div {
      display: flex;
      align-items: center;
      grid-column-gap: 10px;
      padding: 7px 15px;
      background: rgba($primary, 0.2);
      border-radius: 4px;
      min-height: 38px;
      cursor: pointer;
      min-width: 100%;
      svg {
        height: 24px;
        fill: $primary;
      }
      span {
      }
    }
  }
}

.error {
  color: red;
}

.margin_set_1v1 {
  margin-bottom: 30px;
}
.card_box_border {
  padding: 30px;
  border: 1px solid $grey3;
  border-radius: 4px;
}
label.error.select_error_span {
  line-height: normal;
  margin-bottom: 0px;
  display: flex;
}
.input_inner_btn {
  background-color: rgba($grey4, 1);
  color: #fff;
  font-size: 14px;
  padding: 1px 10px;
  text-decoration: none;
  border-radius: 10px;
  display: inline-flex;
  &:hover {
    text-decoration: none;
    background-color: rgba($primary, 0.95);
    color: #fff;
  }
}
.form-control {
  &.input_inner_box {
    height: auto;
  }
}
.static_custom_table_ {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  tr {
    td {
      padding: 8px 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.07);
    }
  }
  tr {
    th {
      padding: 8px 10px;
      font-weight: 600;
      font-size: 14px;
      border-right: 1px solid rgba(0, 0, 0, 0.07);
    }
  }
}
.custom_tab_ {
  &.nav-tabs {
    .nav-link {
      background-color: $grey2;
      border: 1px solid $grey3;
      margin-right: 2px;
      color: $primary;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.show_list_click_va1 {
  display: flex;
  list-style: none;
  margin: 0px;
  flex-wrap: wrap;
  height: auto;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}

.ps--active-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.listing_top_new {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  flex-direction: column;
  a {
    padding: 5px 15px;
    border: 1px solid;
    border-color: $primary;
    align-self: center;
    font-size: 14px;
    display: inline-flex;
    grid-column-gap: 3px;
    border-radius: 25px;
    text-decoration: none;
    color: $primary;
    svg {
      height: 20px;
      width: 20px;
    }
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.doc_file_view_box_v1 {
  display: inline-flex;
  margin-right: 15px;
  margin-bottom: 5px;
  a {
    color: $primary;
    font-size: 14px;
    display: flex;
    border: 1px solid $primary;
    padding: 5px 10px;
    border-radius: 15px;
    transition: ease-in-out 0.5s;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: $primary;
      color: $white;
      text-decoration: none;
    }
  }
  &.edit_doc_file_view_box_v1 {
    position: relative;
    .clg-btn {
      background-color: $white;
      color: $primary;
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      border-radius: 100%;
      align-items: center;
      line-height: normal;
      position: absolute;
      border: 1px solid $primary;
      right: -10px;
      top: -10px;
      cursor: pointer;
    }
  }
}

.redColor {
  color: red;
}
.yellowColor {
  color: yellow;
}
.orangeColor {
  color: orange;
}

.notification_header {
  position: relative;
  margin-right: 30px;
  svg {
    width: 25px;
    height: 25px;
    path {
      fill: $primary;
    }
  }
  .notiCount {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #007bfe;
    color: $white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -10px;
    top: 0;
    font-size: 11px;
  }
}

.product_itme {
  display: grid;
  grid-template-columns: 1fr 144px 40px;
  grid-column-gap: 10px;
  margin-bottom: 5px;
}
.error {
  font-size: 0.875rem;
}
.box_1to1 {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  position: relative;
  padding-left: 30px;
  .box_1to1_number {
    position: relative;
    &::after {
      content: "";
      width: 20px;
      height: 1px;
      background-color: $primary;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-right: -46px;
    }
  }

  .box_1to1_serial {
    padding: 5px 15px;
    border: 1px solid $grey3;
    border-radius: 5px;
    display: grid;
    grid-row-gap: 5px;
    .box_1to1_serial_items {
      display: grid;
      grid-template-columns: 130px 1fr;
      align-items: center;
      &::after {
        content: "";
        width: 25px;
        height: 1px;
        background-color: $grey3;
        position: absolute;
        left: 0px;
      }
    }
    & {
      &::after {
        content: "";
        width: 1px;
        background-color: $grey3;
        position: absolute;
        left: 0px;
        top: -24px;
        height: calc(100% - (0px + 0px));
        transform: translate(0px, 0%);
      }
    }
  }
}
.box_1to2 {
  padding-bottom: 10px;
  .form-control {
    border-radius: 100px;
    border-color: $grey3;
  }
}
