.form-control {
    background-color: $grey2;
    border-color: $grey3;
    font-size: 15px;
}
.Select {
    .Select-control {
        background-color: $grey2;
        border-color: $grey3;
        font-size: 15px;
        input {
            background-color: $grey2;
            border-color: $grey3;
            font-size: 15px;
            border: 0px;
        }
    }
}
.btn_plus_minus {
    background-color: transparent;
    border: 0px;
    display: inline-flex;
    margin: 0px;
    padding: 0;
    svg {
        width: 25px;
        height: 25px;
    }
    &.add_top_space_1 {
        margin-top: 35px;
    }
}

.add_top_space_2 {
    margin-top: 35px;
}
.Select {
    input {
        & + .Select-control {
            & .Select-multi-value-wrapper {
                .Select-placeholder {
                    & + input {
                        & + .error {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    &.Select--multi {
        .Select-input {
            width: auto;
        }
    }
}
.upload_image_AR {
    display: inline-flex;
    border: 1px dashed #000;
    border-radius: 4px;
    padding: 5px;
    position: relative;
    margin-right: 20px;
    .cgl-btn {
        position: absolute;
        right: -15px;
        top: -10px;
        width: 25px;
        height: 25px;
        background-color: #000;
        border-radius: 100%;
        color: #fff;
        display: inline-flex;
        justify-content: center;
        font-size: 12px;
        align-items: center;
        cursor: pointer;
    }
}
label {
    &.upload_btn_custom {
        width: 100%;
        margin: 0px !important;
        position: relative;
        cursor: pointer;
        // input {
        //     visibility: hidden;
        //     position: absolute;
        // }
        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $grey2;
            border: 1px solid;
            border-color: $grey3;
            font-size: 15px;
            padding: 6px 10px;
            border-radius: 4px;
            display: none;
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
}

input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 2px solid #999;
    transition: 0.2s all linear;
    margin-right: 5px;

    position: relative;
    // top: 4px;
}

input[type="radio"]:checked {
    border: 6px solid $primary;
}
