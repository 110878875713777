$primary: #092347;
$primaryHover: #4250f2;
$dark: #0c1427;
$second: #e5e5e5;
$grey1: #bbbbbb;
$grey2: #f6f6f6; /* input */
$grey3: #dddddd;
$grey4: #777777;
$white: #fff;

@for $j from 10 to 50 {
    .font_s-#{$j} {
        font-size: 0px + $j;
    }
}
