.CustomModalCls {
    .modal-dialog {
        .modal-content {
            border: 0px solid #fff;
            .modal-header {
                .modal-title {
                }
                svg.closeIcon {
                    width: 33px;
                    cursor: pointer;
                    fill: $primary;
                }
                border-bottom: 3px solid $dark;
                padding: 10px 15px;
            }
            .modal-body {
            }
            .modal-footer {
            }
        }
    }
    &.bulkUploadModal {
        .upload_file {
            div {
                min-width: 200px;
            }
        }
    }
}
