.footer {
    background: #f9fafb;
    padding: 15px 25px 15px;
    border-top: 1px solid #e8ebf1;
    transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    font-size: calc(0.875rem - 0.05rem);
    font-family: "Overpass", sans-serif;
    font-weight: 400;
    margin-top: auto;
    p {
        margin-bottom: 0px;
    }
}
