.navbar {
    width: calc(100% - 240px);
    height: 60px;
    background: $second;
    border-bottom: 1px solid $grey1;
    display: flex;
    align-items: center;
    padding: 0;
    position: fixed;
    right: 0;
    left: 240px;
    z-index: 978;
    box-shadow: 3px 0 10px 0 #b7c0ce33;
    transition: width 0.1s ease, left 0.1s ease;
    .sidebar-toggler {
        height: 100%;
        border-right: 1px solid #e8ebf1;
        display: flex;
        align-items: center;
        padding: 0 25px;
        display: none;
    }
    .navbar-content {
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 25px;
        padding-right: 25px;
        justify-content: space-between;
    }
    .search-form {
        // width: 100%;
        margin-right: 60px;
        display: flex !important;
        align-items: center !important;
        .input-group {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%;
            .form-control {
                border: none;
                background-color: $second;
                &:focus {
                    box-shadow: none;
                }
            }
        }
        .input-group-prepend {
            color: #c9c8c8;
            width: auto;
            border: none;
            margin-right: -1px;
            .input-group-text {
                border: none;
                background-color: $second;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .userName_detail_header {
        display: flex;
        align-self: center;
        .box_details {
            display: flex;
            span {
                min-width: 30px;
                height: 30px;
                background-color: $primary;
                color: #fff;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                margin-right: 5px;
            }
            div {
                font-size: 15px;
                display: inline-flex;
                align-items: center;
            }
        }
    }
}
