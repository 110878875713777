.custom-ui {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #fff;
    min-width: 350px;
    border: 1px solid #777;
    padding: 15px 0px 30px;
    border-radius: 6px;
}
