.page-breadcrumb {
    margin-bottom: 30px;
    min-height: 35px;
    .breadcrumb {
        background: #f9fafb;
        padding: 0px;
        font-size: 0.875rem;
        align-self: center;
        a {
            color: $grey4;
        }
    }
}
