.sidebar-folded .page-wrapper {
    width: calc(100% - 70px);
    margin-left: 70px;
}
.sidebar-folded .page-wrapper .navbar {
    width: calc(100% - 70px);
    left: 70px;
    right: 0;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar {
    width: 70px;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header {
    width: 70px;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header .sidebar-brand {
    opacity: 0;
    visibility: hidden;
    width: 0;
}
.drop_list_v0 {
    list-style: none;
    padding-left: 25px;
}
.sidebar-folded {
    & .drop_list_v0 {
        padding-left: 0px;
        margin-left: 0px;
    }
}
.sidebar .sidebar-header .sidebar-toggler span + span {
    margin-top: 4px;
}
.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(1) {
    -webkit-animation: ease 0.6s top forwards;
    animation: ease 0.6s top forwards;
}
.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(1) {
    -webkit-animation: ease 0.6s top-2 forwards;
    animation: ease 0.6s top-2 forwards;
}
.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(2) {
    -webkit-animation: ease 0.6s scaled forwards;
    animation: ease 0.6s scaled forwards;
}
.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(2) {
    -webkit-animation: ease 0.6s scaled-2 forwards;
    animation: ease 0.6s scaled-2 forwards;
}
.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(3) {
    -webkit-animation: ease 0.6s bottom forwards;
    animation: ease 0.6s bottom forwards;
}
.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(3) {
    -webkit-animation: ease 0.6s bottom-2 forwards;
    animation: ease 0.6s bottom-2 forwards;
}

@-webkit-keyframes top {
    0% {
        top: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        top: 6px;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        top: 6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@keyframes top {
    0% {
        top: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        top: 6px;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        top: 6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@-webkit-keyframes top-2 {
    0% {
        top: 6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    50% {
        top: 6px;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes top-2 {
    0% {
        top: 6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    50% {
        top: 6px;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@-webkit-keyframes bottom {
    0% {
        bottom: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        bottom: 6px;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        bottom: 6px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
}

@keyframes bottom {
    0% {
        bottom: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        bottom: 6px;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        bottom: 6px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
}

@-webkit-keyframes bottom-2 {
    0% {
        bottom: 6px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    50% {
        bottom: 6px;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@keyframes bottom-2 {
    0% {
        bottom: 6px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    50% {
        bottom: 6px;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@-webkit-keyframes scaled {
    50% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes scaled {
    50% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-webkit-keyframes scaled-2 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scaled-2 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item .nav-link .link-title,
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
    visibility: hidden;
    opacity: 0;
    width: 0;
    margin-left: 0px;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item.nav-category {
    visibility: hidden;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item.nav-category::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #9b9b9b;
    position: absolute;
    top: 5px;
    left: 6px;
    visibility: visible;
}
.sidebar .sidebar-body .nav .nav-item {
    position: relative;
}
