.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
    & .rt-resizable-header-content {
        &::after {
            content: "";
            background-image: url("./../../assets/images/sort.svg");
            width: 18px;
            height: 18px;
            display: inline-flex;
            position: absolute;
            right: 0;
        }
    }
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 0px 0 0 #00000099 !important;
    & .rt-resizable-header-content {
        &::after {
            content: "";
            background-image: url("./../../assets/images/sort-u.svg");
            width: 18px;
            height: 18px;
            display: inline-flex;
            position: absolute;
            right: 0;
        }
    }
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 0px 0 0 #00000099 !important;
    & .rt-resizable-header-content {
        &::after {
            content: "";
            background-image: url("./../../assets/images/sort-d.svg");
            width: 18px;
            height: 18px;
            display: inline-flex;
            position: absolute;
            right: 0;
        }
    }
}
.ReactTable {
    &.text_align_left {
        .rt-thead {
            .rt-tr {
                text-align: left;
            }
        }
        .rt-tbody {
            .rt-td {
                text-align: left;
            }
        }
    }
}
.ReactTable {
    &.text_align_right {
        .rt-thead {
            .rt-tr {
                text-align: right;
            }
        }
        .rt-tbody {
            .rt-td {
                text-align: right;
            }
        }
    }
}
.ReactTable {
    &.text_align_center {
        .rt-thead {
            .rt-tr {
                text-align: center;
            }
        }
        .rt-tbody {
            .rt-td {
                text-align: center;
            }
        }
    }
}

.ReactTable {
    .rt-thead {
        &.-header {
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        }
        .rt-th {
            font-weight: 600;
            font-size: 14px;
            padding: 8px 10px;
            padding-right: 15px;
        }
    }
    .rt-tbody {
        .rt-td {
            font-weight: 400;
            font-size: 14px;
            padding: 8px 10px;
            align-self: center;
            &.two_line {
                div {
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                    overflow: hidden;
                }
            }
        }
        .rt-tr-group {
            border: 0px;
            .rt-tr {
                &.-odd,
                &.-even {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
                &.-padRow {
                    border: none;
                }
            }
        }
    }
    .pagination-bottom {
        display: flex;
        justify-content: center;
        padding: 10px;
        .-pagination {
            display: inline-flex;
            border-top: 0px;
            padding: 0px;
            box-shadow: 0px 0px 0px;

            .-previous,
            .-next {
                flex: 0;
                min-width: 35px;
                line-height: normal;
            }
            .-pageSizeOptions,
            .-pageInfo {
                margin: 0px 10px;
            }
            .-btn {
                padding: 0px 2px;
                justify-content: center;
                align-items: center;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .rt-noData {
        margin-top: -10px;
        padding: 5px;
    }
}
