$screen-sm-max: 576px;
$screen-md-max: 768px;
$screen-lg-max: 992px;
$screen-xl-max: 1200px;
$screem-table-max: 1360px;
$screem-pc-max: 1560px;

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

// Laptop
@mixin Laptop {
    @media (max-width: #{$screem-table-max}) {
        @content;
    }
}

// PC Size
@mixin PC_small {
    @media (max-width: #{$screem-pc-max}) {
        @content;
    }
}

@include PC_small {
    .main-wrapper {
        .page-wrapper {
            .page-content {
                padding: 30px;
            }
        }
    }
}

@include Laptop {
    .main-wrapper {
        .page-wrapper {
            .page-content {
                padding: 30px;
            }
        }
    }
}

@include xl {
    .navbar {
        width: 100%;
        left: 0;
        .navbar-content {
            width: calc(100% - 80px - 1px);
        }
        .sidebar-toggler {
            display: flex;
        }
    }
    .sidebar {
        z-index: 999;
        margin-left: -240px;
        visibility: hidden;
    }
    .main-wrapper {
        .page-wrapper {
            margin-left: 0;
            width: 100%;
        }
    }
    .sidebar-open {
        .sidebar {
            margin-left: 0;
            visibility: visible;
        }
        .main-wrapper::before {
            content: "";
            width: 100vw;
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 99;
            -webkit-transition: all 3s ease;
            transition: all 3s ease;
            z-index: 980;
        }
    }
}

@include lg {
}

@include md {
}

@include sm {
}
