.sidebar {
    width: 240px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: width 0.1s ease, margin 0.1s ease-in-out;
    transition: width 0.1s ease, margin 0.1s ease-in-out;
    z-index: 999;
    .sidebar-header {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        z-index: 999;
        width: 240px;
        -webkit-transition: width 0.1s ease;
        transition: width 0.1s ease;
        background: $second;
        border-bottom: 1px solid $grey1;
        border-right: 1px solid $grey1;
        .sidebar-brand {
            opacity: 1;
            visibility: visible;
            -webkit-transition: opacity 0.5s ease;
            transition: opacity 0.5s ease;
            font-weight: 900;
            // font-size: 25px;
            letter-spacing: -1px;
            color: #fff;
            span {
                color: #727cf5;
                font-weight: 300;
            }
            img {
                width: 140px;
            }
        }
        .sidebar-toggler {
            cursor: pointer;
            width: 18px;
            span {
                display: block;
                width: 100%;
                border-radius: 3px;
                height: 2px;
                background: #9fa2ab;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                position: relative;
                & + span {
                    margin-top: 4px;
                }
            }
            & svg {
                width: 24px;
            }
        }
    }
    .sidebar-body {
        max-height: calc(100% - 60px);
        position: relative;
        height: 100%;
        box-shadow: 0 8px 10px 0 #b7c0ce33;
        background: $second;
        border-right: 1px solid $grey1;
        .nav {
            display: flex;
            flex-direction: column;
            padding: 25px 25px 50px 25px;
            .nav-item {
                &:hover .nav-link {
                    color: #727cf5;
                }
                .nav-link.active {
                    color: #727cf5;
                }
                &.nav-category {
                    color: $dark;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    margin-bottom: 5px;
                    height: 15px;
                    &:not(:first-child) {
                        margin-top: 20px;
                    }
                }
                .nav-link {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    height: 32px;
                    white-space: nowrap;
                    color: $primary;
                    svg {
                        // fill: none;
                        width: 16px;
                        height: 16px;

                        // path {
                        // }
                    }
                    .link-title {
                        font-size: 14px;
                        margin-left: 15px;
                    }
                    &.close {
                        opacity: 1;
                        font-weight: initial;
                        text-shadow: 0 0 black;
                    }
                    &:hover {
                        svg {
                        }
                    }
                }

                &.submenu:hover .nav-link {
                    color: #fff;
                    &.open,
                    &.close {
                        color: #bfc3ce;
                    }
                }
                &.submenu {
                    .link-title {
                        font-weight: 300;
                    }
                }
                &.hover_issue_fixed {
                    &:hover .nav-link {
                        color: initial;
                    }
                    .drop_list_v0 {
                        .nav-link:hover {
                            color: $primaryHover;
                        }
                    }
                }
                &.inventory_icon {
                    .nav-link {
                        &.active {
                            svg {
                                fill: #727cf5;
                            }
                        }
                        &:hover {
                            svg {
                                fill: #727cf5;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar {
    width: 240px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: width 0.1s ease, margin 0.1s ease-in-out;
    transition: width 0.1s ease, margin 0.1s ease-in-out;
    z-index: 999;
}

@import "sidebarAnimation";
